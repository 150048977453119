body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  --white: #f2f2f2;
  --black: #2a2a2a;
  --blue: #89dafa9a;
  --yello: #e9fa899a;
  box-sizing: border-box;
  font-family: "Rethink Sans", sans-serif;
  width: 100%;
  display: block;
}

#home {
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Logo Styles */
.logo {
  position: fixed;
  cursor: pointer;
  top: 0;
  left: 5%;
  height: 150px;
  width: 150px;
}

@media (max-width: 1000px) {
  .logo {
    top: 4%;
    height: 30px;
    width: 30px;
  }
}

.topnav {
  font-size: 20px;
  left: 150%;
  position: fixed;
}

.head {
  margin-top: 0;
  margin-bottom: 0;
  font-family: PP Neue Montreal, sans-serif;
  font-size: 5.5rem;
  font-weight: 450;
  text-align: left;
  color: #333;
  line-height: 1.1;
}

@media (max-width: 991px) {
  .head {
    font-size: 4.5rem;
  }
}

.row {
  display: flex;
  /* Enables flexbox for the container */
  width: 100%;
  justify-content: space-between;
  /* Ensures columns are spaced correctly */
  align-items: center;
  /* Vertically aligns items in the middle */
}

.column {
  padding: 10px;
}

.container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.card {
  flex: 1 1 30%;
  /* This makes each card take up 30% of the row, with some flexibility */
  margin: 10px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  border-radius: 22px;
  box-sizing: border-box;
}

.card-content {
  padding: 10px;
}

.hero-wrap {
  flex-flow: column;
  justify-content: space-between;
  /* align-items: flex-end; */
  height: 100vh;
  padding-top: 8rem;
  padding-bottom: 5.5rem;
  display: flex;
}

@media (max-width: 991px) {
  .hero-wrap {
    grid-column-gap: 4rem;
    grid-row-gap: 4rem;
    justify-content: space-between;
    align-items: flex-start;
    height: auto;
    padding-top: 7rem;
  }
}

#text_id {
  padding: 50px;
  max-width: 60%;
}

@media (max-width: 991px) {
  #text_id {
    max-width: 40rem;
  }
}

#img_id {
  max-width: 40%;
}

.abt-img {
  width: 100%;
  /* Make images responsive */
  max-width: 350px;
  /* Set a max-width to limit image size */
  margin: 0 auto;
  /* Center the image */
  height: auto;
  /* Ensure the aspect ratio is preserved */
  max-height: 230px;
  /* Adjust this to reduce the image height */
  object-fit: contain;
}

.card p {
  font-size: 1em;
}

#cadH {
  font-size: 2.2em;
  text-align: center;
  margin: 80px;
}

#cadF {
  color: var(--black);
  margin: 26px;
  width: 70%;
  display: flex;
  font-size: 1.6em;
  text-align: left;
  margin-top: 40px;
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    /* Stack cards vertically on mobile */
  }

  .card {
    flex: 1 1 100%;
    /* On mobile, each card takes up 100% of the width */
    margin: 10px 0;
    /* Adjust margin between cards */
  }
}

#cad4 {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  background-color: #e7e5ebb0;
  flex-flow: column;
  border-radius: 1rem;
  padding: 2rem;
  height: 24rem;

}

.in-ht {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

#cad4 p {
  position: relative;
  overflow: hidden;
  font-size: 40px;
  font-weight: 400;
}

.web-css {
  border-radius: 1rem;
  width: 8.45rem;
  height: 8.45rem;
  position: relative;
  overflow: hidden;
}

.ser-typ {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  flex-flow: wrap;
  max-width: 30rem;
  display: flex;
}

.srvce-pill {
  background-color: #d1def8;
  border-radius: 20rem;
  padding: .5rem 1rem .625rem;
  line-height: 1;
}

.srvces-pill {
  background-color: #ecefa0;
  border-radius: 20rem;
  padding: .5rem 1rem .625rem;
  line-height: 1;
}

#cad5 {
  display: flex;
  justify-content: space-between;
  background-color: #e7e5ebb0;
  padding: 30px;
}

#cad5 p {
  font-size: 40px;
  font-weight: 400;
}


.box1,
.box2 {
  position: absolute;
  border-radius: 15px;
  /* Optional: make the boxes rounded */
}

#service-head {
  color: #2a2a2a;
  text-align: right;
  font-size: 70px;
  margin: 60px;
  font-weight: 600;
}

#plans-head {
  color: #2a2a2a;
  text-align: center;
  font-size: 80px;
  margin: 60px;
  font-weight: 500;
}

#portfolio-head {
  color: #2a2a2a;
  text-align: left;
  font-size: 70px;
  margin: 60px;
  font-weight: 600;
}

#benifit-head {
  margin-top: 0;
  margin-top: 30px;
  text-align: left;
  font-size: 70px;
  margin: 60px;
  font-weight: 600;
  color: #f5f5f5;
}

.custom-hr {
  border: 0;
  height: 1px;
  /* Change thickness */
  background: #9e9e9e;
  /* Line color */
  margin: 0 30px;
  /* Spacing */
}

.costom-hr {
  border: 0;
  height: 1px;
  /* Change thickness */
  background: #9e9e9e;
  /* Line color */
  /* margin: 0 150px;  */
}

.Our {
  color: #b4aeaec5;
}

/* Hidden state */
.fade-hidden {
  opacity: 0;
  transition: opacity 1s ease-out;
}

/* Fade-in animation */
.fade-in {
  opacity: 1;
  transition: opacity 1s ease-out;
}

#scrolling-animation {
  width: 100%;
}

.glass-wrap {
  height: calc(var(--vh, 1vh)* 100);
  z-index: 3;
  margin-top: -5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  position: -webkit-sticky;
  height: 200vh;
  top: 0;
}

.glass-block {
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  background-color: rgba(42, 42, 42, .05);
  border-radius: 1.5rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.wrap-desktop {
  width: 100%;
  max-width: 32rem;
  position: relative;
  overflow: hidden;
}

.text-wrap {
  width: 100%;
  max-width: 32rem;
  position: relative;
  overflow: hidden;
}

.text-wrap.mobile {
  display: none;
}

.intro_text {
  text-align: center;
  font-size: 2rem;
  line-height: 1.2;
}

.animation-wrap {
  height: 100%;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.sticky-wrap {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100vh;
  padding-bottom: 0;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.block {
  width: 30rem;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: auto;
  transform: rotate(90deg);
}

.block.purple {
  z-index: 2;
  display: block;
  position: relative;
  top: 8rem;
  left: auto;
  right: -13.6rem;
  transform: scale(.1)rotate(90deg);
}

.block.yellow {
  z-index: 1;
  display: block;
  position: relative;
  top: 8rem;
  left: -13.6rem;
  transform: scale(.1)rotate(-90deg);
}

.blur-container {
  filter: blur(4px);
  /* Apply blur to content inside this container */
  position: relative;
  height: auto;
  background-color: inherit;
  pointer-events: none;
}

img {
  max-width: 100%;
  display: inline-block;
  vertical-align: middle;
}

.scroll-box {
  position: absolute;
  left: 10%;
  transform: translateX(-50%);
  /* Centers the box initially */
  transition: transform 0.3s ease-out;
  /*Smooth movement */
  transition: width 0.5s, height 0.3s, top 0.5s, transform 0.5s;
  z-index: -1;
  border-radius: 10px;
  /* Rounded corners for the paper effect */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  /* Subtle shadow */
}

.scroll-box.blue {
  background-color: rgba(172, 19, 233, 0.938);
}

.scroll-box.green {
  left: 20%;
  margin-top: 5%;
  background-color: rgba(240, 220, 40, 0.897);
}

#scrollpar {
  position: sticky;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  font-size: 34px;
  /* Adjust font size as needed */
  color: #000;
  /* Adjust text color as needed */
  z-index: 1000;
  /* Ensure it's on top of other elements */
}

#serTxt {
  background-color: #6eb2bba8;
}

.wave-top {
  display: flex;
  overflow: hidden;

}

.benifit-bg {
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.wave_wrap {
  flex: none;
  display: flex;
  transform-style: preserve-3d;
  will-change: transform;
  animation: waveMove 9s infinite linear;
  overflow: hidden;
}

.grid.benifit {
  grid-column-gap: 6.5rem;
  grid-row-gap: 9.25rem;
  padding: 50px;
  color: #f5f5f5;
}

.grid {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.flex.gap {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
}

.flex {
  flex-flow: column;
  display: flex;
}

.cl-hd {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.line-h.white {
  background-color: #f5f5f5;
}

.overflow {
  position: relative;
  overflow: hidden;
}

.text-height {
  line-height: 1.1;
}

.flex-h {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.icon-wrap {
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  display: flex;
  position: relative;
}

.icon-md {
  z-index: 2;
  width: 1.125rem;
  max-width: none;
  position: relative;
}

.icon.yellow {
  background-color: #c7f15496;
}

h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: PP Neue Montreal, sans-serif;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.1;
}

.icon {
  z-index: 1;
  background-color: var(--white);
  border-radius: 6px;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

@keyframes waveMove {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
    /* Move by half the width to create seamless looping */
  }
}

.wave_image {
  width: 100%;
  /* Each image should take full width */
  object-fit: cover;
  /* Ensure the image scales properly */
}

.grid-2-col.is-plans {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
}

.grid-2-col {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  width: 100%;
  display: grid;
}

.plans-card {
  grid-column-gap: 6rem;
  grid-row-gap: 6rem;
  background-color: #c78458;
  border-radius: 1rem;
  flex-flow: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 2rem;
  display: flex;
}

.flex-v-st.is-gap-md {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
}

.flex-v-st {
  flex-flow: column;
  display: flex;
}

.flex-h-sbt {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.line-h {
  background-color: var(--black);
  width: 100%;
  height: 1px;
}

.dash-line {
  display: block;
  object-fit: cover;
  width: 100%;
  max-width: none;
  height: 1px;
}

.text-size-regular {
  font-size: 1.125rem;
  line-height: 1.3;
}

.text-color-secondary {
  color: #848386c4;
}

.text-style-strikethrough {
  text-decoration: line-through;
}

.position {
  position: relative;
}

.text-weight-medium {
  font-weight: 500;
}

#liness {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

.webflow-badge {
  width: 1.5rem;
  max-width: none;
  height: 1.5rem;
  margin-top: auto;
  margin-bottom: auto;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: -2.125rem;
}

.plans-btn-wrap {
  grid-column-gap: .75rem;
  grid-row-gap: .75rem;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
  display: flex;
}

.btn-secondary.is-no-arrow {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.hide {
  display: none !important;
}

.btn-secondary {
  border: 1px solid var(--black);
  color: var(--black);
  background-color: rgba(42, 42, 42, 0);
  border-radius: 20rem;
  justify-content: center;
  align-items: center;
  height: 3rem;
  padding-left: 1rem;
  padding-right: .5rem;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.btn-secondary.is-white {
  border-color: #f5f5f5;
  color: #f5f5f5;
}

.btn-inner {
  z-index: 2;
  grid-column-gap: .3rem;
  grid-row-gap: .3rem;
  flex-flow: column;
  height: 1.3rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.plans-card.is-purple {
  background-color: #b49068;
  /* color: #f5f5f5; */
}

.btn-main.is-white {
  background-color: #f5f5f5;
  color: #2a2a2a;
}

.btn-bg.is-white {
  background-color: #eee;
}

.line-h.is-grey {
  background-color: #817c7c;
}

/* ------Navbar---- */
.navbar-wrapper {
  z-index: 999;
  padding-top: 1.5rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

@media (max-width: 479px) {
  .navbar-wrapper {
    padding-top: 1.25rem;
  }
}

.glb-padd {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

@media (max-width: 767px) {
  .glb-padd {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.cont-lrg {
  width: 100%;
  max-width: 100rem;
  margin-left: auto !important;
  margin-right: auto !important;
}

.navbar {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  cursor: pointer;
}

@media (max-width: 991px) {
  .navbar {
    justify-content: space-between;
    align-items: center;
  }
}

.logo-wrap {
  color: var(--black);
  width: 8.5rem;
  height: 9.5rem;
  position: absolute;
  left: 0;
}

@media (max-width: 479px) {
  .logo-wrap.is-navbar {
    height: 1.25rem;
  }
}

@media (max-width: 991px) {
  .logo-wrap.is-navbar {
    display: block;
    position: relative;
  }
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

a {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0);
}

.nav-logo {
  width: 100%;
  height: 100%;
  display: block;
}

@media (max-width: 767px) {
  .nav-logo {
    display: block;
  }
}

.nav-logo.is-white {
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 2%;
  right: 0%;
}

.menu-wrap {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(42, 42, 42, .05);
  border-radius: .75rem;
}

.menu {
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
  justify-content: center;
  align-items: center;
  height: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
}

@media (max-width: 991px) {
  .menu {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    flex-flow: column;
    flex: none;
    grid-template-rows: auto auto;
    grid-template-columns: auto 1fr;
    grid-auto-columns: 1fr;
    justify-content: flex-start;
    align-items: start;
    justify-items: end;
    height: auto;
    padding: 2rem;
    display: grid;
    position: static;
    top: 3.5rem;
    bottom: auto;
    left: 0%;
    right: auto;
  }
}

.nav-btn {
  grid-column-gap: 2px;
  grid-row-gap: 2px;
  color: var(--black);
  flex-flow: column;
  justify-content: flex-end;
  align-items: stretch;
  margin-bottom: -3px;
  padding-bottom: 1px;
  font-size: 1.125rem;
  line-height: 1;
  text-decoration: none;
  display: flex;
  overflow: hidden;
}

.nav-btn:hover {
  color: #e28c1ad7;
}

.line-h.hide-tablet {
  height: .5px;
}

.line.h {
  background-color: var(--black);
  width: 100%;
  height: 1px;
}

.btn-main.is-navbar {
  position: absolute;
  right: 0;
}

.btn-main {
  background-color: var(--black);
  color: var(--white);
  border-radius: 20rem;
  justify-content: center;
  align-items: center;
  height: 3rem;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.menu-btn {
  display: none;
}

@media (max-width: 767px) {
  .menu-btn {
    height: 2.75rem;
    font-size: 1rem;
  }
}

@media (max-width: 991px) {
  .menu-btn {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    cursor: pointer;
    background-color: rgba(42, 42, 42, .08);
    border-radius: .75rem;
    justify-content: center;
    align-items: center;
    height: 3rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    font-size: 1.125rem;
    display: flex;
    overflow: hidden;
  }
}

@media (max-width: 479px) {
  .btn-main {
    height: 2.5rem;
    padding-top: .1rem;
    padding-left: 1.375rem;
    padding-right: 1.375rem;
    font-size: .875rem;
  }

  .btn-main.is-navbar {
    right: 4.5rem;

  }
}

@media (max-width: 991) {
  .btn-main.is-navbar {
    position: absolute;
  }
}

.btn-bg {
  background-color: #343434;
  width: 102%;
  height: 102%;
  position: absolute;
  top: -1%;
  bottom: -1%;
  left: -1%;
  right: -1%;
}

/* -----Nav end----- */
/* ---------FAQ--------- */

.faq_wrap {
  grid-column-gap: 3.5rem;
  grid-row-gap: 3.5rem;
  background-color: rgba(42, 42, 42, .05);
  border-radius: 1.5rem;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 6rem;
  display: flex;
  overflow: hidden;
}

h2 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: PP Neue Montreal, sans-serif;
  font-size: 4.5rem;
  font-weight: 500;
  line-height: 1.1;
}

.faq_list {
  align-self: stretch;
}

.faq_dropdown {
  cursor: pointer;
  width: 100%;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  padding-right: 1.5rem;
  position: relative;
}

.flex-h-sbc.is-gap-lg {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}

.flex-h-sbc {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.faq_icon {
  width: 1.125rem;
  height: 1.125rem;
}

.faq_answer {
  overflow: hidden;
}

.faq_answer-inner {
  max-width: 56rem;
  padding-top: 1.75rem;
  padding-right: 3rem;
}

.line-h-ab {
  background-color: var(--black);
  width: 100%;
  height: 1px;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.rich-text p {
  margin-bottom: 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
}

.w-richtext::before {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-richtext::after {
  clear: both;
}

.w-richtext>:last-child,
.w-richtext ol li:last-child,
.w-richtext ul li:last-child {
  margin-bottom: 0 !important;
}

/* -------FAQ END-------------- */
/*-------------Work-----------*/
.space {
  width: 100%;
  padding-top: 1rem;
}

.port-grid {
  grid-column-gap: 2.5rem;
  grid-row-gap: 2.5rem;
  flex-flow: row;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 5.5rem;
  display: grid;
}

.portfolio-column-1 {
  grid-column-gap: 2.5rem;
  grid-row-gap: 2.5rem;
  flex-flow: column;
  flex: 1;
  display: flex;
  bottom: -5.5rem;
}

.porfolio-column-2 {
  grid-column-gap: 2.5rem;
  grid-row-gap: 2.5rem;
  flex-flow: column;
  display: flex;
}

.portfolio_img-wrap {
  aspect-ratio: 576 / 401;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1rem;
  width: 100%;
  overflow: hidden;
}

.sec.is-top {
  width: 100%;
}

/*-----------End---------------*/
/* ------------------contact------------ */
.sec-lrg {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.flex-v-ct.is-gap-lg {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}

.flex-v-ct {
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.flex-v-lt {
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.heading-wrap {
  color: var(--white);
  position: relative;
  overflow: hidden;
}

.heading {
  font-family: PP Neue Montreal, sans-serif;
  font-weight: 500;
}

.heading-wrap.is-footer {
  margin-left: 5.6rem;
}

.heading-wrap.is-mobile {
  display: none;
}

#sm-box {
  width: 30%;
  background-color: var(--white);
  border-radius: 10px;
  cursor: pointer;
}

.icon-md_wrap {
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  display: flex;
  position: relative;
}

.icon-md {
  z-index: 2;
  width: 1.125rem;
  max-width: none;
  position: relative;
}

.icon-bg-md.is-black {
  background-color: var(--black);
}

@media (max-width: 991px) {
  .menu {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    flex-flow: column;
    flex: none;
    grid-template-rows: auto auto;
    grid-template-columns: auto 1fr;
    grid-auto-columns: 1fr;
    justify-content: flex-start;
    align-items: start;
    justify-items: end;
    height: auto;
    padding: 2rem;
    display: grid;
    position: static;
    top: 3.5rem;
    bottom: auto;
    left: 0%;
    right: auto;
  }
}

/* Show the menu button on tablet and mobile */
@media (max-width: 479px) {
  .menu {
    grid-column-gap: 1.375rem;
    grid-row-gap: 1.375rem;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    justify-content: flex-start;
    align-items: flex-end;
    padding: 1.125rem 1.25rem;
    display: flex;
  }
}

/* ---------------------End contact-------- */



/* Responsive design: Adjust the layout for smaller screens */
@media (max-width: 768px) {
  .row {
    flex-direction: column;
    /* Stack the columns vertically on smaller screens */
  }

  .column {
    width: 100%;
    /* Ensure full width for each column on smaller screens */
    text-align: center;
  }
}

/* Mobile View */
@media (max-width: 479px) {
  .navbar {
    background-color: transparent;
    justify-content: space-between;
    align-items: center;
  }

  .navbar-list {
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #333;
    display: none;
    /* Hide the menu initially */
  }

  .navbar-list.open {
    display: flex;
    /* Show the menu when open */
  }

  .navbar-item {
    padding: 1rem;
    text-align: center;
    border-bottom: 1px solid #444;
    background-color: #d3c5c5e1;
  }

  /* Menu Button (Hamburger) */
  .menu-toggle {
    display: block;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333;
    position: fixed;
    top: 2rem;
    right: 1rem;
  }

  .topnav {
    display: none;
  }
}

@media (max-width: 991) {
  .navbar {
    justify-content: space-between;
    align-items: center;
  }
}

/* Hide the menu button on larger screens */
@media (min-width: 1001px) {
  .menu-toggle {
    display: none;
  }
}